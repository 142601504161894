html {
    scroll-behavior: smooth;
}
* {
    box-sizing: border-box;
}
a {
    color: $default-color;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}

h2, h2.title {
    margin-bottom: 45px;
    font-weight: 700;
}

img {
    max-width: 100%;
}

.bold {
    font-weight: 700;
}

.slider-wrapper {
    position: relative;
}

.swiper-container {
    overflow: hidden;
}

.swiper-button-prev.slider-arrow,
.swiper-button-next.slider-arrow {
    color: white;
    width: fit-content;
    &:after {
        font-size: 30px;
        @media screen {
            font-size: 25px;
        }
    }
}

.swiper-button-prev.slider-arrow {
    left: -50px;
    @media screen and (max-width: $xl){
        left: -20px;
        right: 100%;
    }
    @media screen and (max-width: $md){
        left: 0;
        right: auto;
    }
}

.swiper-button-next.slider-arrow {
    right: -50px;
    @media screen and (max-width: $xl){
        right: -20px;
        left: 100%;
    }
    @media screen and (max-width: $md){
        right: 0;
        left: auto;
    }
}

svg.icon {
    @include styleForIcon ($default-color);
    @include size (60px, 60px);
    &-close {
        @include styleForIcon ($dark-color);
        @include size (16px, 16px);
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
    }
}

a.btn, .btn {
    display: block;
    @include styleForText (15px, none, 500, none);
    border: 1px solid $default-color;
    border-radius: 20px;
    width: fit-content;
    cursor: pointer;
    &_size-small {
        padding: 12px 22px;
        &:hover {
            background-color: white;
            color: $dark-color;
        }
    }

    &_size-large {
        padding: 15px 50px;
        @media screen and (max-width: $xs){
            padding: 15px 40px;
            height: 52px;
        }
    }

    &_color-orange {
        color: $default-color;
        border: 0px;
        background-image: linear-gradient(90deg, #ff9000 0%, #ff3801 99.56%);
    }
}
label, input, button {
    display: block;
    outline: none;
}
input {
    @include size (100%, 47px);
    border-radius: 4px;
    border: 1px solid rgba(6,6,7,.15);
    padding: 15px 11px 25px;
}
input[name="accept"] {
    height: 25px;
    width: 20%;
    max-width: 25px
}
label {
    @include styleForText (15px, none, 500, none);
    padding-bottom: 10px;
}
body {
    @include styleForText (16px, $default-color, none, 24px);
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: $primary-color;
}

.title {
    @include styleForText(30px, none, 500, 1.5);
    text-align: center;
}

.subtitle {
    font-size: 18px;
    text-align: center;
}

.container {
    width: 100%;
    max-width: 1150px;
    padding: 0px 25px;
    margin: 0 auto;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.section {
    padding: 70px 0px 60px;
    @media screen and (max-width: $xl){
        padding: 50px 0px;
    }
    &-first {
        padding: 0;
    }

    &-video {
        padding: 0;
    }
    &-content {
        a {
            text-decoration: underline;
        }
    }
    
}
